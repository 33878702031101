import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitVideo from "./QuarklycommunityKitVideo";
const defaultProps = {
	"padding": "88px 0 88px 0",
	"margin": "0px 0px 0px 0px",
	"quarkly-title": "Video-6"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"lg-padding": "0px 0px 0px 0px",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"margin": "0px 0px 48px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"padding": "0px 200px 0px 200px",
			"display": "flex",
			"width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"color": "--dark",
			"font": "normal 900 72px/1.2 --fontFamily-googleLora",
			"text-align": "center",
			"sm-font": "normal 700 42px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Ashram videos"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--grey",
			"text-align": "center",
			"font": "--lead",
			"children": <>
				The #1 Spiritual Retreat, Find Peace Within{"\n\n"}
			</>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-flex-wrap": "wrap",
			"flex-wrap": "wrap",
			"width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"lg-width": "50%",
			"md-width": "100%",
			"align-items": "center",
			"justify-content": "center",
			"width": "50%",
			"display": "flex",
			"padding": "15px 15px 15px 15px"
		}
	},
	"quarklycommunityKitVideo": {
		"kind": "QuarklycommunityKitVideo",
		"props": {
			"src": "https://screenshot.ukit.com/videos/quarklyVideo.mp4",
			"width": "100%",
			"controls": true,
			"playOnHover": false,
			"overflow-x": "hidden",
			"overflow-y": "hidden"
		}
	},
	"quarklycommunityKitVideoOverride": {
		"kind": "Override",
		"props": {
			"slot": "Video Tag",
			"overflow-x": "hidden",
			"width": "100%",
			"border-radius": "24px",
			"overflow-y": "hidden"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "15px 15px 15px 15px",
			"lg-width": "50%",
			"md-width": "100%",
			"align-items": "center",
			"justify-content": "center",
			"width": "50%"
		}
	},
	"quarklycommunityKitVideo1": {
		"kind": "QuarklycommunityKitVideo",
		"props": {
			"src": "https://screenshot.ukit.com/videos/quarklyVideo.mp4",
			"width": "100%",
			"controls": true,
			"playOnHover": false,
			"overflow-x": "hidden",
			"overflow-y": "hidden"
		}
	},
	"quarklycommunityKitVideoOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Video Tag",
			"overflow-x": "hidden",
			"width": "100%",
			"border-radius": "24px",
			"overflow-y": "hidden"
		}
	}
};

const Imageslider1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" max-width="1220px" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<QuarklycommunityKitVideo {...override("quarklycommunityKitVideo")}>
					<Override {...override("quarklycommunityKitVideoOverride")} />
				</QuarklycommunityKitVideo>
			</Box>
			<Box {...override("box3")}>
				<QuarklycommunityKitVideo {...override("quarklycommunityKitVideo1")}>
					<Override {...override("quarklycommunityKitVideoOverride1")} />
				</QuarklycommunityKitVideo>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Imageslider1, { ...Section,
	defaultProps,
	overrides
});
export default Imageslider1;