import React from "react";
import theme from "theme";
import { Theme, Text, Image, LinkBox, Box, Link, Section, Input, Button, Icon, Structure, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia, Formspree } from "@quarkly/components";
import * as Components from "components";
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-6" padding="24px 0px 24px 0px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				justify-content="space-between"
				max-width="100%"
				width="100%"
				padding="0px 24px 0px 24px"
			/>
			<Components.QuarklycommunityKitMobileSidePanel
				menuPosition="full"
				breakpoint="lg"
				width="68%"
				sm-width="50%"
				md-width="50%"
				lg-width="40%"
				lg-justify-content="flex-start"
				md-justify-content="flex-end"
				display="block"
				position="relative"
				font="-6px sans-serif"
			>
				<Override slot="Children" md-display="flex" font="19px sans-serif" />
				<Override
					slot="Content"
					padding="0px 0px 29px 0px"
					background="rgba(255, 255, 255, 0)"
					lg-background="#ffffff"
					lg-margin="0px 0px 0px 0px"
					font="10px sans-serif"
				/>
				<Override
					slot="Button Text"
					font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-transform="uppercase"
					letter-spacing="1px"
					sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					sm-margin="0px 2px 0px 0px"
					lg-margin="0px 0px 0px 0px"
				/>
				<Override
					slot="Button Icon :closed"
					category="fi"
					icon={FiMenu}
					size="32px"
					padding="5px 7px 5px 7px"
					border-radius="50px"
				/>
				<Override
					slot="Button Icon"
					width="28px"
					height="28px"
					category="ai"
					icon={AiOutlineMenu}
					color="--dark"
					size="24px"
					lg-width="32px"
					lg-height="32px"
				/>
				<Override
					slot="Cross"
					lg-width="32px"
					lg-height="32px"
					size="32px"
					top="24px"
					right="24px"
				/>
				<Override slot="Wrapper" width="100% border-box" align-content="flex-start" />
				<Text margin="0px 0px 0px 0px" text-align="left" font="bold 24px sans-serif">
					<br />
					<br />
					REG NO: IN-UP38492236005292U{"\n\n"}
				</Text>
				<Box
					align-items="center"
					lg-justify-content="center"
					lg-align-items="flex-start"
					justify-content="flex-start"
					display="flex"
					lg-flex-direction="column"
					lg-margin="0px auto 0px auto"
					lg-min-width="300px"
					lg-max-width="1280px"
					lg-width="90%"
					lg-padding="24px 0px 48px 0px"
				>
					<Box
						display="none"
						lg-width="100%"
						lg-margin="0px 0px 24px 0px"
						lg-display="flex"
						lg-padding="12px 0px 12px 0px"
					>
						<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
							<Image src="https://uploads.quarkly.io/6457b971d28fe100213a0f35/images/flower-logo-template.svg?v=2023-08-06T19:34:51.878Z" display="block" width="36px" height="36px" />
							<Text
								margin="0"
								md-margin="0px 0 0px 0"
								text-align="left"
								font="--lead"
								sm-margin="0px 0 0px 0"
								display="block"
								lg-font="--headline3"
							>
								Company
							</Text>
						</LinkBox>
					</Box>
					<Components.QuarklycommunityKitMenu
						display="flex"
						filterMode="exclude"
						filterPages="/index"
						grid-gap="4px"
						lg-flex-direction="column"
						lg-padding="6px 0px 6px 0px"
						lg-margin="0px 0px 24px 0px"
						align-items="center"
						flex-wrap="wrap"
						overflow-x="visible"
						overflow-y="visible"
						lg-align-items="flex-start"
					>
						<Override
							slot="link"
							color="--darkL2"
							hover-color="--primary"
							font="--base"
							text-decoration-line="initial"
							transition="color 0.1s ease 0s"
							lg-font="--lead"
						/>
						<Override
							slot="item-active"
							border-width="0 0 1px 0"
							border-style="solid"
							border-color="--color-darkL2"
							lg-border-width="0 0 0 2px"
						/>
						<Override slot="item" padding="6px 6px 6px 6px" lg-padding="6px 12px 6px 12px" />
						<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
						<Override slot="link-about" />
					</Components.QuarklycommunityKitMenu>
					<Box
						display="none"
						padding="12px 0"
						justify-content="flex-end"
						align-items="flex-start"
						flex-direction="row"
						width="40%"
						lg-width="100%"
						md-justify-content="flex-start"
						lg-justify-content="flex-start"
						lg-padding="0px 0 12px 0"
						lg-flex-direction="column"
						lg-margin="0px 0px 24px 0px"
						lg-display="flex"
						lg-grid-gap="24px"
					>
						<Link
							border-radius="8px"
							href="#"
							text-decoration-line="initial"
							border-style="solid"
							font="--base"
							color="--darkL1"
							margin="0px 0px 0px 34px"
							border-width="2px"
							padding="4px 12px 5px 12px"
							border-color="--color-darkL1"
							lg-margin="0px 0px 0px 0px"
							lg-padding="10px 16px 10px 16px"
							lg-font="--lead"
						>
							Sign Up
						</Link>
						<Link
							font="--base"
							text-decoration-line="initial"
							margin="0px 0px 0px 16px"
							background="--color-darkL1"
							border-radius="8px"
							href="#"
							color="#ffffff"
							padding="6px 12px 7px 12px"
							lg-padding="12px 16px 12px 16px"
							lg-font="--lead"
							lg-margin="0px 0px 0px 0px"
						>
							Book A Demo
						</Link>
					</Box>
					<Box
						width="25%"
						display="none"
						lg-width="100%"
						lg-flex-direction="column"
						lg-align-items="flex-start"
						lg-display="flex"
						justify-content="space-around"
						align-items="center"
						flex-wrap="wrap"
						lg-margin="24px 0px 0px 0px"
					>
						<SocialMedia
							instagram="https://instagram.com/instagram"
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/quarklyapp/"
							youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw"
							lg-display="flex"
							lg-grid-gap="12px"
						>
							<Override slot="link-twitter" margin="0px 0px 0px 5px">
								<div />
							</Override>
							<Override
								slot="link"
								border-radius="50%"
								background="transparent"
								hover-color="--light"
								display="flex"
								margin="0 5px 0 5px"
								padding="5x 5px 5px 5px"
								width="32px"
								height="32px"
								align-items="center"
								justify-content="center"
							/>
							<Override slot="icon" size="32px" border-radius="50px" color="--grey" />
							<Override slot="link-facebook" margin="0px 5px 0px 0px">
								<div />
							</Override>
						</SocialMedia>
					</Box>
				</Box>
			</Components.QuarklycommunityKitMobileSidePanel>
			<Box
				display="flex"
				padding="12px 0"
				justify-content="center"
				align-items="center"
				flex-direction="row"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="20%"
				md-justify-content="flex-start"
				md-order="-1"
				width="max-content"
			>
				<LinkBox
					flex-direction="row"
					href="/index"
					display="flex"
					grid-gap="12px"
					width="570px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11:00:58.770Z"
						display="block"
						width="120px"
						height="90px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="normal bold 25px/1.2 --fontFamily-googleLora"
						sm-margin="0px 0 0px 0"
						display="block"
						width="420px "
						color="rgb(80 101 39)"
					>
						SHREE RAM RAGHUVAR ASHRAM
					</Text>
				</LinkBox>
			</Box>
			<Box
				display="flex"
				padding="12px 0"
				justify-content="center"
				align-items="center"
				flex-direction="row"
				width="40%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="40%"
				md-justify-content="flex-start"
				md-display="none"
			>
				<Link
					border-radius="8px"
					href="#"
					text-decoration-line="initial"
					border-style="solid"
					sm-padding="5px 10px 5px 10px"
					md-padding="5px 10px 5px 10px"
					sm-margin="10px 0px 10px 0px"
					font="--base"
					color="--darkL1"
					margin="0px 0px 0px 34px"
					md-margin="10px 0px 0px 0px"
					border-width="2px"
					padding="4px 12px 5px 12px"
					border-color="--color-darkL1"
				>
					EBOOK
				</Link>
				<Link
					font="--base"
					text-decoration-line="initial"
					margin="0px 0px 0px 16px"
					background="--color-secondary"
					border-radius="8px"
					md-margin="0px 0px 0px 14px"
					href="#"
					color="#ffffff"
					padding="6px 12px 7px 12px"
					md-order="1"
				>
					DONATE
				</Link>
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			min-height="100vh"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Image
				src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z"
				display="block"
				width="300px"
				height="400px"
				text-align="left"
				srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 57px/1.2 --fontFamily-googleLora"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-transform="uppercase"
					text-align="center"
				>
					Discover Peace at Our Ashram{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					text-align="center"
				>
					Experience tranquility at Shree Ram Raghuvar Ashram, where mindful living meets spiritual awakening in harmony with nature.{"\n\n"}
				</Text>
				<Box
					sm-flex-direction="column"
					sm-text-align="center"
					align-items="center"
					justify-content="space-between"
					flex-direction="row"
					flex-wrap="wrap"
					display="flex"
					align-content="space-around"
					padding="0px 0px 0px 40px"
				>
					<Link
						href="#"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="#FFC400"
						text-decoration-line="initial"
						font="normal bold 20px/1.5 --fontFamily-sans"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
						flex-direction="row"
					>
						Become A Member{" "}
					</Link>
				</Box>
			</Box>
			<Image
				src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z"
				display="block"
				height="400px"
				width="300px"
				srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Components.Imageslider1 />
		<Components.QuarklycommunityKitCarousel>
			<Override slot="Slide Image" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/235546783_882278456052059_2367902711404688197_n.jpg?v=2024-10-15T13:30:33.609Z" width="auto" height="auto" />
			<Override slot="Slide" width="auto" height="50% content-box" />
			<Override slot="Slide Image 1" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z" />
			<Override slot="Slide Image 2" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z" />
			<Override slot="Slide Image 3" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z" />
		</Components.QuarklycommunityKitCarousel>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 66px/1.2 --fontFamily-googleLora"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="1170px"
				>
					Discover Inner Peace at Our Ashram{"\n\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal 300 22px/1.5 --fontFamily-sans">
					Discover our ashram's origins and the values that inspire our community.{"\n\n"}
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				height="max-content"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Ram Chandra Ji Maharaj (Lala Ji  Maharaj){"\n\n"}
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj){"\n\n"}
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					padding="7px 7px 40px 7px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z"
						border-radius="16px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						padding="0px 0px 18px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="31px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj){"\n\n"}
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
			</Box>
			<Section>
				<Override slot="SectionContent" width="auto" />
				<Link
					href="#"
					text-decoration-line="initial"
					color="--dark"
					font="normal bold 20px/1.5 --fontFamily-googleLora"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-secondary"
					lg-padding="12px 20px 12px 20px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					text-align="center"
					position="static"
				>
					Learn More
				</Link>
			</Section>
		</Section>
		<Section padding="80px 0 80px 0" background="rgba(237, 242, 246, 0)" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 72px/1.2 --fontFamily-googleLora"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Upcoming Events
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					Upcoming events and schedules
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					17/10/2024  17:30 — 18:30
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Meditation Session
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"After years of seeking peace, the Shree Ram Raghuvar Ashram provides tranquility and spiritual growth in ways you've never imagined.{"\n\n"}
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12:34:19.102Z"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Anuj Mohan Das Ji{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Founder
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					18/10/2024  17:30 — 18:30{"\n\n"}
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Yoga Session
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Ethan Tremblay
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Yoga Instructor
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					19/10/2024  17:30 — 18:30{"\n\n"}
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Spritual Gathering
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Adriana Williams
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Manager
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				margin="0px 0px 32px 0px"
				width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="normal 900 72px/1.2 --fontFamily-googleLora" color="--dark" text-align="center">
					Recent Events{"\n\n"}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					font="--lead"
					display="block"
					width="50%"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
				>
					A lot of events recently completed
					<br />
					{"\n\n"}
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				width="100%"
			>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12:58:35.294Z"
						display="block"
						height="auto"
						width="100% "
						padding="5px 0px 0px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/300703278_1125051075108128_5726891413852904526_n.jpg?v=2024-10-15T12%3A58%3A35.294Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Sawan{" "}
							<br />
							Poojan
						</Text>
					</Box>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12:44:40.297Z"
						display="block"
						height="auto"
						width="100% "
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/448353770_1528563511423547_1739026841674273777_n.jpg?v=2024-10-15T12%3A44%3A40.297Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Ashram Inauguration Darbhanga
						</Text>
					</Box>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12:53:35.549Z"
						display="block"
						height="auto"
						width="100% border-box"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Sarbat
							<br />
							{" "}Vitran
						</Text>
					</Box>
				</Box>
				<Box
					position="relative"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="flex-start"
					padding="24px 24px 0px 24px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12:52:54.508Z"
						display="block"
						height="auto"
						width="100% "
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/276146012_1022326705380566_7798890778271365775_n.jpg?v=2024-10-15T12%3A52%3A54.508Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box padding="0px 20px 0px 20px" margin="0px 0px 0px 0px">
						<Text
							margin="21px 0px 0px 0px"
							font="--headline3"
							display="block"
							text-align="center"
							color="--darkL1"
						>
							Meditation Session
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section>
			<Override slot="SectionContent" width="auto" />
			<Link
				href="#"
				text-decoration-line="initial"
				color="--dark"
				font="normal bold 20px/1.5 --fontFamily-googleLora"
				padding="12px 24px 12px 24px"
				border-radius="8px"
				background="--color-secondary"
				lg-padding="12px 20px 12px 20px"
				transition="background-color 0.2s ease-in-out 0s"
				hover-transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				text-align="center"
				position="static"
			>
				View More
			</Link>
		</Section>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="normal bold 72px/1.2 --fontFamily-googleLora" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					TEACHINGS{"\n\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal bold 20px/1.5 --fontFamily-googleLora">
					A complete collection of GURU PARIVAR in the form of EBOOKS{"\n\n"}
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 1
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 200
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 2
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 300
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 3
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 500
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Box
			display="flex"
			align-items="center"
			flex-direction="column"
			justify-content="center"
			margin="0px 0px 64px 0px"
			width="100%"
			sm-margin="0px 0px 30px 0px"
			padding="0px 200px 0px 200px"
			lg-padding="0px 0px 0px 0px"
		>
			<Text margin="0px 0px 16px 0px" font="normal bold 72px/1.2 --fontFamily-googleLora" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Our Founder{"\n\n"}
			</Text>
			<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal bold 20px/1.5 --fontFamily-googleLora">
				Teachings of Shree Ram Raghuvar Ashram{" \n\n"}
			</Text>
		</Box>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image
					max-width="340px"
					src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12:34:19.102Z"
					md-margin="0px 0px 20px 0px"
					srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="normal bold 16px/1.5 --fontFamily-googleLora">
					Anuj Mohan Das Ji Maharaj, Founder of  Shri Ram Raghuvar Ashram{"\n\n"}
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="normal bold 42px/1.2 --fontFamily-googleLora" md-margin="0px 0px 30px 0px" />
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="normal 500 28px/1.2 --fontFamily-googleLora">
					"After years of seeking peace, the Shree Ram Raghuvar Ashram provides tranquility and spiritual growth in ways you've never imagined. The embrace of nature and profound wisdom shared here truly enriched your soul, offering a sanctuary from the bustling world and deepening path of self-discovery."
					<br />
					<br />
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" />
			</Box>
		</Section>
		<Section background="#667e3a" color="--dark" padding="64px 0 64px 0">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box>
						<Text font="--headline2" max-width="500px" margin="10px 0 0 0" color="#ffffff">
							Join Our Spiritual Journey{"\n\n"}
						</Text>
					</Box>
					<Text
						font="normal 300 12px/1.5 --fontFamily-googleLora"
						color="#ffffff"
						letter-spacing="1px"
						text-transform="uppercase"
						margin="0"
						padding="8px 0px 0px 0px"
					>
						Stay inspired with our updates on yoga, enlightenment, and spiritual awakening.{"\n\n"}
					</Text>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" lg-width="100%">
					<Box>
						<Formspree endpoint="xeqpgrlv">
							<Box
								gap="16px"
								display="grid"
								flex-direction="row"
								flex-wrap="wrap"
								grid-template-columns="repeat(2, 1fr)"
								grid-gap="16px"
							>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Name
									</Text>
									<Input width="100%" name="name" type="text" />
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Email
									</Text>
									<Input width="100%" type="email" name="email" />
								</Box>
								<Box display="flex" flex-direction="column" grid-column="1 / span 2">
									<Text font="--base" margin="0 0 4px 0" color="#ffffff">
										Message
									</Text>
									<Input as="textarea" rows="4" width="100%" name="message" />
								</Box>
								<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
									<Button>
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Footer-10" md-padding="40px 0 40px 0">
			<Text margin="0px 0px 0px 0px" />
			<Box
				display="flex"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				md-padding="0 0px 30px 0px"
				lg-padding="0 0px 50px 0px"
				sm-flex-direction="column"
				md-flex-direction="column"
				md-display="flex"
				md-grid-gap="52px"
			>
				<Box
					lg-margin="0px 0px 0px 0px"
					width="30%"
					display="flex"
					lg-width="50%"
					sm-width="100%"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					sm-margin="0px 0px 0px 0px"
				>
					<Section>
						<Override
							slot="SectionContent"
							width="50% content-box"
							align-items="flex-start"
							display="flex"
							flex-direction="row"
							justify-content="flex-start"
							flex-wrap="no-wrap"
						/>
						<Image
							src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11:00:58.770Z"
							display="block"
							width="120px"
							height="85px"
							padding="0px 0px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="bold 20px --fontFamily-googleLora"
							text-align="center"
							padding="19px 0px 0px 0px"
							color="rgb(80 101 39)"
						>
							SHREE RAM RAGHUVAR ASHRAM
							<br />
							{"\n\n"}
						</Text>
					</Section>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						grid-template-columns="repeat(5, 1fr)"
						grid-gap="16px 24px"
						md-align-self="flex-start"
						align-self="flex-start"
						padding="0px 0px 0px 35px"
						font="23px sans-serif"
						height="30px"
						align-items="center"
						flex-direction="column"
						justify-content="space-between"
						flex-wrap="wrap"
						grid-row-gap="16px"
					>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaFacebook}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaTwitterSquare}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaGithub}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaYoutube}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
					</Box>
				</Box>
				<Section padding="24px 0 24px 2px">
					<Override slot="SectionContent" padding="0px 0px 0px 29px" />
					<Structure letter-spacing="1.5 rem">
						<Override slot="Content">
							<Override slot="cell-0">
								<Text margin="0px 0px 0px 0px" font="bold 36px --fontFamily-googleLora">
									Contact{" "}
								</Text>
							</Override>
							<Override slot="cell-1">
								<Text margin="0px 0px 0px 0px" font="bold 36px --fontFamily-googleLora">
									Address
								</Text>
							</Override>
							<Override slot="cell-2">
								<Text margin="0px 0px 0px 0px" font="bold 36px --fontFamily-googleLora">
									Email
								</Text>
							</Override>
						</Override>
					</Structure>
					<Structure letter-spacing="1.5 rem">
						<Override slot="Content">
							<Override slot="cell-0">
								<Text margin="0px 0px 0px 0px" font="bold 23px --fontFamily-googleLora">
									+91-8795-742-208
								</Text>
							</Override>
							<Override slot="cell-1">
								<Text margin="0px 0px 0px 0px" font="bold 23px --fontFamily-googleLora">
									8/223 Arya Nagar, Kanpur 208002
								</Text>
							</Override>
							<Override slot="cell-2">
								<Text margin="0px 0px 0px 0px" font="bold 23px --fontFamily-googleLora">
									abc@123.com
								</Text>
							</Override>
						</Override>
					</Structure>
				</Section>
			</Box>
			<Text margin="0px 0px 0px 0px">
				<Em />
				{"\n"}©   2024 Developed by Complete IT Solution{"\n\n"}
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"670e4a9e1b94b1002431b396"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});